import React from 'react'
import { Link } from 'gatsby'
import icon5 from '../../assets/images/icons/icon5.png'
import { getConversion } from '../../api/images'

const DocumentCard = ({ document, show_miniature }) => {
    const { name, original_url } = document
    return (
        <div className='col-lg-4 col-md-6 col-12 mb-3'>
            {show_miniature  && JSON.stringify( document.generated_conversions) !== '{}' ?
                <>
                    <div className="single-events-box" >
                        <div className="image">
                            <Link to={original_url} target='_blank' className='d-block'>
                                <img src={getConversion(document)} alt={name} />
                            </Link>
                        </div>
                        <div className="content">
                            <h3>
                                <Link to={original_url} target='_blank' className='w-100 text-center d-block'>
                                    {name}
                                </Link>
                            </h3>
                        </div>
                    </div >
                </>
                :
                <>
                    <div className='card h-100 medias-list'>
                        <Link to={original_url} target='_blank'>
                            <img src={icon5} alt='banner' />

                            <div>
                                <h5>{name}</h5>
                            </div>
                        </Link>
                    </div>
                </>
            }

        </div>
    )
}

export default DocumentCard


